



































































import { StakeLockPeriods } from "@/constants";
import { FixedNumber } from "@ethersproject/bignumber";
import { computed } from "mobx";
import { Observer } from "mobx-vue";
import moment from "moment";
import { Component, Vue, Provide, Ref, Inject, Prop } from "vue-property-decorator";
import { TrainingGroundViewmodel } from "../viewmodels/training-ground-viewmodel";

@Observer
@Component({
  components: {
    SelectLockPeriod: () => import("../components/select-lock-period.vue"),
    StakeResult: () => import("../components/stake-result.vue"),
  },
})
export default class IncreaseUnlockTime extends Vue {
  @Inject() vm!: TrainingGroundViewmodel;

  isValidated = false;
}
